import React, { useState, useEffect } from "react";
import Switch from "@material-ui/core/Switch"; // Add this import
import { useLocation, useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import ZoneService from "../services/ZoneService";
import { capitalize } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 280,
  },
}));

const ZoneEditPage = () => {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const [isActive, setIsActive] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [lotNumber, setLotNumber] = useState("");
  const [name, setName] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [address, setAddress] = useState("");
  const [openTime, setOpenTime] = useState("06:00");
  const [closeTime, setCloseTime] = useState("23:00");
  const [errorMessages, setErrorMessages] = useState("");
  const [errorLocNameMessages, setErrorLocNameMessages] = useState("");
  const [price, setPrice] = useState("");
  const [choice, setChoice] = useState("daily");

  const handleSubmit = async () => {
    setLoading(true);

    const zoneObj = {
      locationId: location.state.location._id,
      zoneNumber: lotNumber,
      price: Number(price),
      isHourly: choice === "hourly",
      openTime,
      closeTime,
      archieved: isActive,
    };

    // make call here
    try {
      const zone = new ZoneService();
      await zone.update(zoneId, zoneObj);
      history.push({
        pathname: `/locations/${location.state.location._id}/zones`,
      });
    } catch (err) {
      console.log(err);
      setErrorLocNameMessages(err);
      setErrorMessages(err.message);
    }

    setLoading(false);
  };

  const handleChange = (event) => {
    setChoice(event.target.value);
  };

  const getZone = async () => {
    const {
      location: { address, name },
      zone: {
        _id: zoneId,
        price,
        openTime,
        closeTime,
        zoneNumber,
        isHourly,
        archieved,
      },
    } = location.state;

    setLotNumber(zoneNumber);
    setPrice(price);
    setOpenTime(openTime);
    setCloseTime(closeTime);
    setChoice(isHourly ? "hourly" : "daily");
    setAddress(address);
    setName(name);
    setZoneId(zoneId);
    setIsActive(archieved);
  };

  useEffect(() => {
    getZone();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div>
        <div
          style={{
            textAlign: "center",
            margin: "35px 0",
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          Edit Zone
        </div>
        <div style={{ textAlign: "center", marginBottom: "30px" }}>
          <div style={{ fontWeight: "bold" }}>{capitalize(name)}</div>
          <div style={{ fontSize: "12px", color: "gray" }}>{address}</div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextField
          label={"Zone Number"}
          variant="outlined"
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start"># </InputAdornment>
            ),
          }}
          onChange={(e) => setLotNumber(e.target.value)}
          value={lotNumber}
          disabled={isLoading}
        />{" "}
        <br />
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginRight: "30px",
            }}
          >
            <div>
              <Radio
                checked={choice === "hourly"}
                onChange={handleChange}
                value="hourly"
                label="hourly"
                name="radio-button-hourly"
                inputProps={{ "aria-label": "hourly radio" }}
              />{" "}
              <span>Hourly</span>
            </div>
            <div>
              <Radio
                checked={choice === "daily"}
                onChange={handleChange}
                value="daily"
                label="daily"
                name="radio-button-daily"
                inputProps={{ "aria-label": "daily radio" }}
              />{" "}
              <span>Daily</span>
            </div>
          </div>
          <br />
          <TextField
            label={`Price ${capitalize(choice)}`}
            variant="outlined"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$ </InputAdornment>
              ),
            }}
            placeholder={`${price}/${choice}`}
            onChange={(e) => setPrice(e.target.value)}
            value={price}
            disabled={isLoading}
          />{" "}
          <div>
            <TextField
              id="time"
              label="Open Time"
              type="time"
              variant="outlined"
              defaultValue={openTime}
              onChange={(e) => setOpenTime(e.target.value)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </div>
          <div>
            <TextField
              id="time"
              label="Close Time"
              type="time"
              variant="outlined"
              defaultValue={closeTime}
              onChange={(e) => setCloseTime(e.target.value)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </div>
          <div
            style={{
              marginTop: "20px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "16px",
            }}
          >
            <Switch
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
              color="primary"
              disabled={isLoading}
            />
            <span>
              Location is
              <span
                style={{
                  fontWeight: "bold",
                  marginLeft: "8px",
                  color: isActive ? "#ff0000" : "#00ab66",
                  fontSize: "18px",
                }}
              >
                {isActive ? "Inactive" : "Active"}
              </span>
            </span>
          </div>
        </div>
        {errorLocNameMessages && (
          <div style={{ color: "red", textAlign: "center" }}>
            {errorLocNameMessages}
          </div>
        )}
        {errorMessages && (
          <div style={{ color: "red", textAlign: "center" }}>
            {errorMessages}
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SubmitLocationButton onClick={(e) => handleSubmit(e)}>
            Update
          </SubmitLocationButton>
        </div>
      </div>
    </div>
  );
};

const SubmitLocationButton = styled.button`
  height: 50px;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: lightcoral;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #f6b2b2;
    color: #fff;
  }
`;

export default ZoneEditPage;
