import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { fetchLocations, fetchMyLocations } from "../actions/index";
import { isAuth } from "../utils/AuthHelpers/AuthCookieHandler";
import CircleLoader from "../components/shared/CircleLoader";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "87px",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  vendorLocations: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  addLocation: {
    padding: "15px 0",
    backgroundColor: "#27AE60",
    borderRadius: "5px",
    border: "none",
    width: "150px",
    marginLeft: "20px",
    fontWeight: 900,
    color: "white",
    textAlign: "center",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "75px",
    },
  },
}));

const LocationPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);

  var getPosition = function () {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
      );
    } else {
      return new Promise((resolve) => resolve({}));
    }
  };

  const getLocations = async () => {
    setIsLoading(true);
    if (
      (isAuth() && isAuth().role === "vendor") ||
      (isAuth() && isAuth().role === "odogwu")
    ) {
      const ml = await fetchMyLocations();
      setLocations(ml);
    } else {
      try {
        const position = await getPosition();
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        const latlng = { lat, lng };
        if (lat && lng) {
          const l = await fetchLocations(latlng);
          setLocations(l);
        }
      } catch (err) {
        console.log(err);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getLocations();
    // eslint-disable-next-line
  }, []);

  const goToZoneAddPage = (location) => {
    history.push({
      pathname: `/locations/${location._id}/new/zone`,
      state: location,
    });
  };

  const goToZonePage = (location) => {
    history.push({
      pathname: `/locations/${location._id}/zones`,
      state: location,
    });
  };

  const goToLocationEditPage = (location) => {
    history.push({
      pathname: `/locations/${location._id}/edit`,
      state: location,
    });
  };

  const goToLocationAddPage = () => {
    return history.push("/locations/add");
  };

  return (
    <div
      style={{
        marginBottom: "50px",
        display: "flex",
        flexDirection: "column",
        gap: 20,
      }}
    >
      <div
        style={{
          textAlign: "center",
          margin: "35px 0",
          fontSize: "30px",
          fontWeight: "bold",
        }}
      >
        Locations
      </div>
      {isAuth() &&
        (isAuth().role === "vendor" || isAuth().role === "odogwu") && (
          <div
            onClick={() => goToLocationAddPage()}
            className={classes.addLocation}
          >
            Add Location
          </div>
        )}
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <CircleLoader />
        </div>
      ) : (
        <div className={classes.vendorLocations}>
          {locations && locations.length > 0 ? (
            locations.map((loc, idx) => {
              return (
                <div
                  key={loc._id}
                  style={{
                    padding: "14px",
                    borderRadius: "10px",
                    margin: "20px",
                    cursor: "pointer",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  }}
                >
                  <h3 style={{ textTransform: "capitalize" }}>{loc.name}</h3>
                  <p>{loc.address}</p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: !isAuth()
                        ? "center"
                        : isAuth().role === "customer"
                        ? "center"
                        : "space-between",
                    }}
                  >
                    {isAuth() && isAuth().role !== "customer" && (
                      <>
                        <ActionButton onClick={() => goToZoneAddPage(loc)}>
                          Add Zone
                        </ActionButton>
                        <ActionButton onClick={() => goToLocationEditPage(loc)}>
                          Edit
                        </ActionButton>
                      </>
                    )}
                    <ActionButton
                      style={{
                        width: !isAuth()
                          ? 280
                          : isAuth().role === "customer"
                          ? 280
                          : 115,
                      }}
                      onClick={() => goToZonePage(loc)}
                    >
                      View Zones
                    </ActionButton>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                textAlign: "center",
                width: "100%",
                fontSize: "20px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ textAlign: "center" }}>
                We cannot find any parking locations...
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ActionButton = styled.button`
  height: 50px;
  width: 90px;
  cursor: pointer;
  border: none;
  margin: 0 7px;
  border-radius: 5px;
  background-color: #27ae60;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  &:hover {
    background-color: #2cc36b;
  }
`;

export default LocationPage;
